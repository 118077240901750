export const postsRelations = [
  'section_hero_and_sidebar.customer',
  'template_blog_post.author',
  'section_featured_customer.customer_reference',
  'section_featured.list',
  'section_featured.item',
  'template_case_study.sidebar_fields',
  'template_blog_post.author',
  'template_events.speakers',
  'template_events.sponsors',
  'template_speaker.author',
  'template_case_study.page_config',
  'template_blog_post.page_config',
  'template_events.page_config',
  'template_news.page_config',
  'template_resources.page_config',
  'template_resources_ebook.page_config',
];
