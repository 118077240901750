import React, { useState, useEffect, useContext } from 'react';
import { LanguageContext } from '@/base/context/Language/provider';
import { getStories } from '@/base/services/storyblok';
import { postsRelations } from '@/base/consts/relations';
import { createAcceptableResourcesSlugs } from '@/base/helpers/strings';
import RelatedPostsList from '@/components/modules/RelatedPostsList';
import ModuleWrapper from '@/components/modules/ModuleWrapper/ModuleWrapper';
import type { PostCardTypes } from '@/components/modules/PostCard/PostCard.types';
import type { RelatedContentTypes } from './RelatedContent.types';

export default function RelatedContent({
  title,
  posts,
  type,
  top_spacing = 'md',
  bottom_spacing = 'md',
}: RelatedContentTypes) {
  const [fetchedPosts, setFetchedPosts] = useState<StoryblokEntry<PostCardTypes>[]>([]);

  const {
    state: { language },
  } = useContext(LanguageContext);

  function fetchFilteredPosts() {
    return getStories<PostCardTypes>({
      queryParameters: {
        // @ts-ignore - API INCOMPATIBILITY
        resolve_relations: postsRelations,
        is_startpage: 0,
        by_slugs: createAcceptableResourcesSlugs(language, type),
        per_page: 3,
        sort_by: 'first_published_at:desc',
        filter_query: {
          type: {
            in: type,
          },
          hide_from_lists: {
            in: 'false',
          },
        },
      },
    });
  }

  useEffect(() => {
    if (!type) return;
    fetchFilteredPosts().then(response => {
      if (!response?.data.length) return null;
      setFetchedPosts(response?.data);
    });
  }, [language, type]);

  return (
    <ModuleWrapper topSpacing={top_spacing} bottomSpacing={bottom_spacing} tag="section">
      <RelatedPostsList
        title={title}
        posts={posts?.length ? posts : fetchedPosts}
        usedInModularTemplate
        genericPostsCategories
      />
    </ModuleWrapper>
  );
}
